(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/configs/assets/javascripts/fallback.js') >= 0) return;  svs.modules.push('/components/sportinfo/configs/assets/javascripts/fallback.js');
"use strict";


const {
  matchState: {
    SportinfoMatchState: MatchState
  },
  constants: {
    SportinfoPathNames,
    ContentProvider,
    SvsViewType,
    SportinfoSportradarWidgets: SportradarWidgets
  }
} = svs.components.sportinfo.common;
const {
  ConfigBuilder
} = svs.components.sportinfo.configBuilder;
const {
  content,
  menuItems
} = new ConfigBuilder().addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfo,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.Mutuals, tab => {
  tab.set(MatchState.NotStarted, [{
    type: SportradarWidgets.MatchPreview,
    provider: ContentProvider.SportRadar,
    viewProps: {
      disableMoreStats: true,
      disableExpandButton: true
    }
  }]);
}).build();
setGlobal('svs.components.sportinfo.configs.sportConfigs', {
  fallbackConfig: {
    menuItems,
    content,
    config: 'fallback' 
  }
});

 })(window);