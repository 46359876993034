(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/configs/assets/javascripts/common.js') >= 0) return;  svs.modules.push('/components/sportinfo/configs/assets/javascripts/common.js');
"use strict";


const {
  matchState: {
    SportinfoMatchState: MatchState
  },
  constants: {
    SportinfoPathNames,
    SportinfoSportradarWidgets: SportradarWidgets,
    SvsViewType
  }
} = svs.components.sportinfo.common;
const {
  ContentProvider
} = svs.components.sportinfo.common.constants;
const {
  ConfigBuilder
} = svs.components.sportinfo.configBuilder;
const {
  menuItems,
  content
} = new ConfigBuilder().addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfo,
    provider: ContentProvider.Svs
  }]).prepend(MatchState.Ongoing, [{
    type: SportradarWidgets.LiveMatchTracker,
    provider: ContentProvider.SportRadar
  }]);
}).addTab(SportinfoPathNames.Statistic, tab => {
  tab.set(MatchState.NotStarted, [{
    type: SportradarWidgets.HeadToHead,
    provider: ContentProvider.SportRadar,
    viewProps: {
      components: ['form', 'lastmatches']
    }
  }]).setMany([MatchState.Ongoing, MatchState.Finished], [{
    type: SportradarWidgets.Scoreboard,
    provider: ContentProvider.SportRadar,
    title: 'Matchhändelser',
    viewProps: {
      disableWidgetHeader: true,
      disableMatchInfo: true,
      expandedGoalScorers: true
    }
  }, {
    type: SportradarWidgets.GeneralStatistics,
    provider: ContentProvider.SportRadar,
    title: 'Livestatistik'
  }]);
}).addTab(SportinfoPathNames.Mutuals, tab => {
  tab.setMany([MatchState.Ongoing, MatchState.NotStarted], [{
    type: SportradarWidgets.MatchPreview,
    provider: ContentProvider.SportRadar,
    viewProps: {
      disableMoreStats: true,
      disableExpandButton: true
    }
  }]);
}).addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.Table, tab => {
  tab.setAll([{
    type: SportradarWidgets.LiveTable,
    provider: ContentProvider.SportRadar,
    viewProps: {
      showOnlySelectedGroup: true
    }
  }]);
}).build();
setGlobal('svs.components.sportinfo.configs.sportConfigs', {
  commonConfig: {
    menuItems,
    content,
    config: 'common' 
  }
});

 })(window);