(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/configs/assets/javascripts/overrides/sport/tennis.js') >= 0) return;  svs.modules.push('/components/sportinfo/configs/assets/javascripts/overrides/sport/tennis.js');
"use strict";


const {
  matchState: {
    SportinfoMatchState: MatchState
  },
  constants: {
    SportinfoPathNames,
    SportinfoSportradarWidgets: SportradarWidgets,
    SvsViewType
  }
} = svs.components.sportinfo.common;
const {
  ContentProvider
} = svs.components.sportinfo.common.constants;
const {
  ConfigBuilder
} = svs.components.sportinfo.configBuilder;
const {
  menuItems,
  content
} = new ConfigBuilder().addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfo,
    provider: ContentProvider.Svs
  }]).prepend(MatchState.Ongoing, [{
    type: SportradarWidgets.LiveMatchTracker,
    provider: ContentProvider.SportRadar
  }]);
}).addTab(SportinfoPathNames.Statistic, tab => {
  tab.set(MatchState.NotStarted, [{
    type: SportradarWidgets.HeadToHead,
    provider: ContentProvider.SportRadar,
    viewProps: {
      components: ['form', 'lastmatches']
    }
  }]);
}).addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}).build();
setGlobal('svs.components.sportinfo.configs.sportConfigs.sportTypeOverrides', {
  tennisConfig: {
    menuItems,
    content,
    config: 'tennis' 
  }
});

 })(window);