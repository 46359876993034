(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/configs/assets/javascripts/overrides/sport/trotting.js') >= 0) return;  svs.modules.push('/components/sportinfo/configs/assets/javascripts/overrides/sport/trotting.js');
"use strict";


const {
  matchState: {
    SportinfoMatchState: MatchState
  },
  constants: {
    SportinfoPathNames,
    ContentProvider,
    SvsViewType
  }
} = svs.components.sportinfo.common;
const {
  ConfigBuilder
} = svs.components.sportinfo.configBuilder;
const {
  content,
  menuItems,
  defaultRoutes
} = new ConfigBuilder().addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}, {
  label: 'Startfält'
}).addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfo,
    provider: ContentProvider.Svs
  }]);
}, {
  label: 'Loppinfo'
}).setDefaultRoutes(SportinfoPathNames.News, [MatchState.NotStarted, MatchState.Ongoing, MatchState.Finished]).build();
setGlobal('svs.components.sportinfo.configs.sportConfigs.sportTypeOverrides', {
  trottingConfig: {
    menuItems,
    content,
    defaultRoutes,
    config: 'trotting' 
  }
});

 })(window);